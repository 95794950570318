import * as CONST from "./constant";

export const vehicleTypes = (payload, callBack) => {
  return {
    type: CONST.VEHICLE_TYPE,
    payload,
    callBack,
  };
};

export const vehicleTypeSuccess = (payload) => ({
  type: CONST.VEHICLE_TYPE_SUCCESS,
  payload,
});

export const vehicleTypeFail = (payload) => ({
  type: CONST.VEHICLE_TYPE_FAIL,
  payload,
});
