import {
  put,
  call,
  take,
  every,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import * as CONST from "./constant";
import * as ACTION from "./action";
import * as API from "../../services/ApiCalls";
import { toast } from "react-toastify";

function* nearbyRestaurantCategoriesSaga({ payload, callBack }) {
  try {
    const response = yield call(API.NEARBY_RESTAURANT, payload);
    if (response.data.status == "success") {
      // toast.success(response.data.message);
      // callBack && callBack(response.data);
      yield put(ACTION.nearbyRestaurantCategoriesSuccess(response.data));
    } else {
      // toast.error(response?.data?.message);
    }
  } catch (error) {
    yield put(ACTION.nearbyRestaurantFail(error));
  }
}

function* nearbyRestaurantSaga({ payload, callBack }) {
  try {
    const response = yield call(API.NEARBY_RESTAURANT, payload);
    if (response.data.status == "success") {
      // toast.success(response.data.message);
      // callBack && callBack(response.data);
      yield put(ACTION.nearbyRestaurantSuccess(response.data));
    } else {
      // toast.error(response?.data?.message);
    }
  } catch (error) {
    yield put(ACTION.nearbyRestaurantFail(error));
  }
}

function* nearbyFreshMarketRestaurantSaga({ payload, callBack }) {
  try {
    const response = yield call(API.NEARBY_RESTAURANT, payload);
    if (response.data.status == "success") {
      // toast.success(response.data.message);
      // callBack && callBack(response.data);
    }

    yield put(ACTION.nearbyFreshMarketRestaurantSuccess(response.data));
  } catch (error) {
    yield put(ACTION.nearbyRestaurantFail(error));
  }
}

function* collectionTabSaga({ payload, callBack }) {
  try {
    const response = yield call(API.COLLECTION_TAB, payload);
    if (response.data.status == "success") {
      // toast.success(response.data.message);
      // callBack && callBack(response.data);
    }

    yield put(ACTION.collectionTabSuccess(response.data));
  } catch (error) {
    yield put(ACTION.collectionTabFail(error));
  }
}

function* restaurantDetailSaga({ payload, callBack }) {
  // console.log(payload, "payloadpayloadpayload")
  try {
    const response = yield call(API.RESTAURANT_DETAIL, payload);

    // console.log(response, "RESPONSe")
    yield put(ACTION.restaurantDetailSuccess(response.data.data));
  } catch (error) {
    yield put(ACTION.restaurantDetailFail(error));
  }
}

function* freshMarketDetailSaga({ payload, callBack }) {
  // console.log(payload, "payloadpayloadpayload")
  try {
    const response = yield call(API.FRESH_MARKET_DETAIL, payload);

    // console.log(response, "RESPONSe")
    yield put(ACTION.freshMarkettDetailSuccess(response.data.data));
  } catch (error) {
    yield put(ACTION.freshMarkettDetailFail(error));
  }
}

function* NearbyRestaurantSaga() {
  yield takeEvery(
    CONST.NEARBY_RESTAURANT_CATEGORIES,
    nearbyRestaurantCategoriesSaga
  );
  yield takeEvery(CONST.NEARBY_RESTAURANT, nearbyRestaurantSaga);
  yield takeEvery(
    CONST.NEARBY_FRESH_RESTAURANT,
    nearbyFreshMarketRestaurantSaga
  );
  yield takeEvery(CONST.RESTAURANT_DETAIL, restaurantDetailSaga);
  yield takeEvery(CONST.FRESH_MARKET_DETAIL, freshMarketDetailSaga);
  yield takeEvery(CONST.COLLECTION_TAB, collectionTabSaga);
}

export default NearbyRestaurantSaga;
