import React from "react";
import Footer from "../../Component/Footer/Footer";
import Header from "../../Component/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BannerSec from "../../Component/DriverSignUP/Bannersec/Index";
import DriverNavigation from "../../Component/DriverSignUP/DriverNavigation/Index";
import IconSec from "../../Component/DriverSignUP/IconSec/Index";
import HowItWork from "../../Component/DriverSignUP/HowItWork/Index";
import DeliveryRequirement from "../../Component/DriverSignUP/DeliveryRequirement/Index";
import AppLink from "../../Component/DriverSignUP/Applink/Index";

export const DriverSignUp = () => {
  return (
    <>
      {/* <Header /> */}
      <div className="py-2">
        <DriverNavigation />
        <BannerSec />
        <IconSec />
        <HowItWork />
        <DeliveryRequirement />
        <AppLink />
      </div>
      <Footer />
    </>
  );
};
export default DriverSignUp;
