export const NEARBY_RESTAURANT_CATEGORIES = "NEARBY_RESTAURANT_CATEGORIES";
export const NEARBY_RESTAURANT_CATEGORIES_SUCCESS = "NEARBY_RESTAURANT_CATEGORIES_SUCCESS";
export const NEARBY_RESTAURANT_CATEGORIES_FAIL = "NEARBY_RESTAURANT_CATEGORIES_FAIL";

export const NEARBY_RESTAURANT = "NEARBY_RESTAURANT";
export const NEARBY_FRESH_RESTAURANT = "NEARBY_FRESH_RESTAURANT";
export const NEARBY_RESTAURANT_SUCCESS = "NEARBY_RESTAURANT_SUCCESS";
export const NEARBY_RESTAURANT_FAIL = "NEARBY_RESTAURANT_FAIL";
export const NEARBY_FRESH_RESTAURANT_SUCCESS = "NEARBY_FRESH_RESTAURANT_SUCCESS";

export const RESTAURANT_DETAIL = "RESTAURANT_DETAIL";
export const RESTAURANT_DETAIL_SUCCESS = "RESTAURANT_DETAIL_SUCCESS";
export const RESTAURANT_DETAIL_FAIL = "RESTAURANT_DETAIL_FAIL";

export const FRESH_MARKET_DETAIL = "FRESH_MARKET_DETAIL";
export const FRESH_MARKET_DETAIL_SUCCESS = "FRESH_MARKET_DETAIL_SUCCESS";
export const FRESH_MARKET_DETAIL_FAIL = "FRESH_MARKET_DETAIL_FAIL";

///////================COLLECTION_TAB====================/////////

export const COLLECTION_TAB = "COLLECTION_TAB";
export const COLLECTION_TAB_SUCCESS = "COLLECTION_TAB_SUCCESS";
export const COLLECTION_TAB_FAIL = "COLLECTION_TAB_FAIL";
export const LAT_LNG = "LAT_LNG";
