import { combineReducers } from "redux";
import LoginReducer from "./Login/reducer";
import NearbyRestaurantReducer from "./Restaurant/reducer";
import SignupReducer from "./Signup/reducer";
import ProfileReducer from "./Profile/reducer";
import AddressReducer from "./Dashboard/reducer";
import BannerReducer from "./Banner/reducer";
import PrivacyReducer from "./privacyPolicy/reducer";
import BlogReducer from "./Blog/reducer";
import VehicleReducer from "./Vehicles/reducer";

const rootReducer = combineReducers({
  login: LoginReducer,
  signup: SignupReducer,
  restaurant: NearbyRestaurantReducer,
  profile: ProfileReducer,
  address: AddressReducer,
  banner: BannerReducer,
  policy: PrivacyReducer,
  Blog: BlogReducer,
  Vehicle: VehicleReducer,
});

export default rootReducer;
