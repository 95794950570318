import React, { useState, useCallback, useRef, Fragment } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import notifyErrors from "../../../helpers/notify-errors";
import getDynamicImage from "../../../helpers/get-dynamic-image";
import { toast } from "react-toastify";
import { stepOneSchema, stepTwoSchema } from "./validation";
import PhoneInput from "react-phone-input-2";
import { signupDriver } from "../../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import createFormData from "../../../helpers/create-form-data";
import { vehicleTypes as getVehicleTypes } from "../../../Redux/Vehicles/action";
import { useEffect } from "react";
import { GoogleAutocomplete } from "../../../Common/addressInput/addressInput";

const SignUpForm = ({ showSignUp, setShowSignUp }) => {
  const [step, setStep] = useState("0");
  const dispatch = useDispatch();
  const { vehicleTypes } = useSelector((s) => s.Vehicle);
  const initialValues = {
    name: "",
    email: "",
    password: "",
    mobileNumber: "",
    mobileCountryCode: "",
    address: "",
    profileImage: null,
    vehicleName: "",
    vehicleType: "",
    vehicleModel: "",
    vehicleMake: "",
    plateNumber: "",
    vehicleYear: "",
    vehicleColor: "",
    licenseNumber: "",
    licenseExpiryDate: "",
    vehicleImage: null,
    licenseImage: null,
    insuranceImage: null,
  };

  const onSubmit = useCallback(
    (values) => {
      // console.log("submitted", values);

      const data = createFormData({
        name: values.name,
        countryCode: values.mobileCountryCode,
        mobileNumber: values.mobileNumber,
        email: values.email,
        password: values.password,
        address: values.address,
        vehicleName: values.vehicleName,
        vehicleMake: values.vehicleMake,
        vehicleModel: values.vehicleModel,
        vehicleYear: values.vehicleYear,
        vehicleColor: values.vehicleColor,
        vehicleType: values.vehicleType,
        vehicleTypeId: vehicleTypes.find(
          (item) => item.carType === values.vehicleType
        )?._id,
        plateNumber: values.plateNumber,
        licenceNumber: values.licenseNumber,
        licenceExpDate: values.licenseExpiryDate,
        chargeType: "perMile",
        fixedFee: 0,
        perMileFee: 2,
      });
      // console.log("sent data", data.address);
      const callBack = (data) => {
        // console.log("resss data", data);
        if (data.status === "success") {
          setShowSignUp(false);
          setStep("0");
        } else {
          //toast.error(data.message);
        }
      };
      dispatch(signupDriver(data, callBack));
    },
    [vehicleTypes]
  );
  const validationSchema = step === "0" ? stepOneSchema : stepTwoSchema;

  useEffect(() => {
    const callBack = (data) => {};
    dispatch(getVehicleTypes({}, callBack));
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {function FormikForm({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit: formikSubmit,
        isSubmitting,
        validateForm,
        setFieldValue,
        setFieldTouched,
        setValues,
        setTouched,
        resetForm,
        /* and other goodies */
      }) {
        const profileImageRef = useRef();
        const vehicleImageRef = useRef();
        const licenseImageRef = useRef();
        const insuranceImageRef = useRef();

        const handleSubmit = async (e) => {
          e.preventDefault();
          const errors = await validateForm();
          notifyErrors(errors);
          formikSubmit(e);
        };

        const handleNext = async (e) => {
          e.preventDefault();
          const errors = await validateForm();
          notifyErrors(errors);
          setTouched({
            name: true,
            email: true,
            password: true,
            mobileNumber: true,
            mobileCountryCode: true,
            address: true,
            profileImage: true,
          });
          if (!(Object.keys(errors).length > 0)) {
            setStep("1");
          }
        };

        const handleMobileNumber = (value, others) => {
          setFieldValue("mobileNumber", value);
          setFieldValue("mobileCountryCode", "+" + others.dialCode);
        };

        const handleProfileImage = (e) => {
          console.log(e.target.files?.[0], "e.target.files[0]");
          if (e && e.target.files?.length > 0) {
            if (e.target.files[0].size > 5242880) {
              toast.error("Max file size limit is 5mb");
              return;
            }
            setFieldValue("profileImage", e.target.files[0]);
          } else if (!e.target.files) {
            profileImageRef.current.click();
          }
        };
        const handleVehicleImage = (e) => {
          if (e && e.target.files?.length > 0) {
            if (e.target.files[0].size > 5242880) {
              toast.error("Max file size limit is 5mb");
              return;
            }
            setFieldValue("vehicleImage", e.target.files[0]);
          } else if (!e.target.files) {
            vehicleImageRef.current.click();
          }
        };
        const handleLicenseImage = (e) => {
          if (e && e.target.files?.length > 0) {
            if (e.target.files[0].size > 5242880) {
              toast.error("Max file size limit is 5mb");
              return;
            }
            setFieldValue("licenseImage", e.target.files[0]);
          } else if (!e.target.files) {
            licenseImageRef.current.click();
          }
        };
        const handleInsuranceImage = (e) => {
          if (e && e.target.files?.length > 0) {
            if (e.target.files[0].size > 5242880) {
              toast.error("Max file size limit is 5mb");
              return;
            }
            setFieldValue("insuranceImage", e.target.files[0]);
          } else if (!e.target.files) {
            insuranceImageRef.current.click();
          }
        };

        const handleAddress = (address, event) => {
          setFieldValue("address", address.address);
        };

        const profileImage = getDynamicImage(
          values.profileImage,
          "/images/profile.png"
        );
        const vehicleImage = getDynamicImage(values.vehicleImage, "");
        const licenseImage = getDynamicImage(values.licenseImage, "");
        const insuranceImage = getDynamicImage(values.insuranceImage, "");

        useEffect(() => {
          if (!showSignUp) {
            resetForm();
          }
        }, [showSignUp]);

        return (
          <Fragment>
            <Modal
              className="account-form signUP"
              show={showSignUp}
              onHide={() => setShowSignUp(false)}
              centered
              scrollable={true}
            >
              <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                  Sign <span className="theme-clr"> Up</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form className="px-md-5">
                  {step === "0" ? (
                    <Row>
                      <Col lg="12" className="my-2 text-center pb-5">
                        <div
                          className="uploadFile position-relative d-inline-flex mb-2"
                          onClick={handleProfileImage}
                        >
                          <input
                            type="file"
                            className="position-absolute"
                            onChange={handleProfileImage}
                            hidden
                            value={""}
                            ref={profileImageRef}
                            accept="image/*"
                          />
                          <div className="inner">
                            <img
                              src={profileImage}
                              alt=""
                              className="img-fluid rounded-circle border"
                            />
                          </div>
                        </div>
                        <p
                          className="m-0 fw-sbold"
                          onClick={handleProfileImage}
                          style={{ cursor: "pointer" }}
                        >
                          Select Profile Picture
                        </p>
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Name
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill="none"
                            >
                              <path
                                d="M0.878847 15.8969C1.32097 14.8496 1.96257 13.8984 2.76791 13.0961C3.5708 12.2915 4.5219 11.65 5.56869 11.207C5.57806 11.2023 5.58744 11.2 5.59682 11.1953C4.13666 10.1406 3.18744 8.42266 3.18744 6.48438C3.18744 3.27344 5.789 0.671875 8.99994 0.671875C12.2109 0.671875 14.8124 3.27344 14.8124 6.48438C14.8124 8.42266 13.8632 10.1406 12.4031 11.1977C12.4124 11.2023 12.4218 11.2047 12.4312 11.2094C13.4812 11.6523 14.4234 12.2875 15.232 13.0984C16.0366 13.9013 16.6781 14.8524 17.121 15.8992C17.5562 16.924 17.7909 18.0228 17.8124 19.1359C17.8131 19.161 17.8087 19.1858 17.7995 19.2091C17.7904 19.2324 17.7767 19.2537 17.7592 19.2716C17.7417 19.2895 17.7209 19.3037 17.6978 19.3134C17.6747 19.3231 17.65 19.3281 17.6249 19.3281H16.2187C16.1156 19.3281 16.0335 19.2461 16.0312 19.1453C15.9843 17.3359 15.2578 15.6414 13.9734 14.357C12.6445 13.0281 10.8796 12.2969 8.99994 12.2969C7.12025 12.2969 5.35541 13.0281 4.0265 14.357C2.74213 15.6414 2.01556 17.3359 1.96869 19.1453C1.96635 19.2484 1.88432 19.3281 1.78119 19.3281H0.374941C0.349918 19.3281 0.325146 19.3231 0.302088 19.3134C0.279028 19.3037 0.258146 19.2895 0.240673 19.2716C0.223198 19.2537 0.209486 19.2324 0.200344 19.2091C0.191202 19.1858 0.186815 19.161 0.187441 19.1359C0.210878 18.0156 0.442909 16.9258 0.878847 15.8969ZM8.99994 10.5156C10.0757 10.5156 11.0882 10.0961 11.8499 9.33438C12.6117 8.57266 13.0312 7.56016 13.0312 6.48438C13.0312 5.40859 12.6117 4.39609 11.8499 3.63437C11.0882 2.87266 10.0757 2.45312 8.99994 2.45312C7.92416 2.45312 6.91166 2.87266 6.14994 3.63437C5.38822 4.39609 4.96869 5.40859 4.96869 6.48438C4.96869 7.56016 5.38822 8.57266 6.14994 9.33438C6.91166 10.0961 7.92416 10.5156 8.99994 10.5156Z"
                                fill="#2E3E5C"
                              />
                            </svg>
                          </span>
                          <input
                            type={"text"}
                            value={values.name}
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.name && touched.name && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.name}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Email
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="19"
                              viewBox="0 0 21 19"
                              fill="none"
                            >
                              <path
                                d="M16 5L11.8528 9.39271C11.068 10.2024 9.96772 10.2024 9.18289 9.39271L5 5"
                                stroke="#2E3E5C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.82927 1H15.1441C16.4872 1.0149 17.7655 1.586 18.6813 2.57922C19.5972 3.57244 20.0707 4.90238 19.9914 6.25813V12.7419C20.0707 14.0976 19.5972 15.4276 18.6813 16.4208C17.7655 17.414 16.4872 17.9851 15.1441 18H5.82927C2.9444 18 1 15.6411 1 12.7419V6.25813C1 3.35891 2.9444 1 5.82927 1Z"
                                stroke="#2E3E5C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          <input
                            type={"text"}
                            value={values.email}
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.email && touched.email && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.email}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Password
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill="none"
                            >
                              <path
                                d="M14 8V5.75621C14 3.12992 11.7656 1.00005 9.01042 1.00005C6.25524 0.988551 4.01206 3.10798 4 5.73532V5.75621V8"
                                stroke="#2E3E5C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.015 19H4.98502C2.78443 19 1 17.4278 1 15.4868V11.5132C1 9.57222 2.78443 8 4.98502 8H13.015C15.2156 8 17 9.57222 17 11.5132V15.4868C17 17.4278 15.2156 19 13.015 19Z"
                                stroke="#2E3E5C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 12V14"
                                stroke="#2E3E5C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          <input
                            type={"password"}
                            value={values.password}
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.password && touched.password && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.password}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Mobile Number
                        </label>
                        <div className="icon-with-text position-relative">
                          <PhoneInput
                            type="text"
                            name="mobileNumber"
                            placeholder="Your Mobile"
                            onChange={handleMobileNumber}
                            onBlur={() => setFieldTouched("mobileNumber", true)}
                            value={values.mobileNumber}
                            country={"us"}
                            //onlyCountries={["us", "mx"]}
                            inputClass="w-100"
                          />
                        </div>
                        {errors.mobileNumber && touched.mobileNumber && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.mobileNumber}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="12" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Address
                        </label>
                        <div className="icon-with-text position-relative">
                          <GoogleAutocomplete
                            address={values.address}
                            name={"address"}
                            onChange={handleAddress}
                            className="w-100"
                            onBlur={() => setFieldTouched("address", true)}
                          />
                        </div>
                        {errors.address && touched.address && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.address}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col lg="12" className="my-2 mt-4 btn-wrp">
                        <Button
                          onClick={handleNext}
                          className="common-btn d-flex align-items-center justify-content-center border-0 w-100"
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {step === "1" ? (
                    <Row>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Vehicle Name
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M5 15.8333V16.2708C5 16.6181 4.88195 16.9097 4.64584 17.1458C4.40972 17.3819 4.11111 17.5 3.75 17.5C3.40278 17.5 3.1075 17.3783 2.86417 17.135C2.62084 16.8917 2.49945 16.5967 2.5 16.25V10L4.25 5C4.33334 4.75 4.4825 4.54861 4.6975 4.39583C4.9125 4.24305 5.15222 4.16666 5.41667 4.16666H14.5833C14.8472 4.16666 15.0869 4.24305 15.3025 4.39583C15.5181 4.54861 15.6672 4.75 15.75 5L17.5 10V16.2708C17.5 16.6181 17.3819 16.9097 17.1458 17.1458C16.9097 17.3819 16.6111 17.5 16.25 17.5C15.9028 17.5 15.6078 17.3783 15.365 17.135C15.1222 16.8917 15.0006 16.5967 15 16.25V15.8333H5ZM4.83334 8.33333H15.1667L14.2917 5.83333H5.70834L4.83334 8.33333ZM6.25 13.3333C6.59722 13.3333 6.8925 13.2117 7.13584 12.9683C7.37917 12.725 7.50056 12.43 7.5 12.0833C7.5 11.7361 7.37834 11.4408 7.135 11.1975C6.89167 10.9542 6.59667 10.8328 6.25 10.8333C5.90278 10.8333 5.6075 10.955 5.36417 11.1983C5.12084 11.4417 4.99945 11.7367 5 12.0833C5 12.4306 5.12167 12.7258 5.365 12.9692C5.60834 13.2125 5.90334 13.3339 6.25 13.3333ZM13.75 13.3333C14.0972 13.3333 14.3925 13.2117 14.6358 12.9683C14.8792 12.725 15.0006 12.43 15 12.0833C15 11.7361 14.8783 11.4408 14.635 11.1975C14.3917 10.9542 14.0967 10.8328 13.75 10.8333C13.4028 10.8333 13.1075 10.955 12.8642 11.1983C12.6208 11.4417 12.4994 11.7367 12.5 12.0833C12.5 12.4306 12.6217 12.7258 12.865 12.9692C13.1083 13.2125 13.4033 13.3339 13.75 13.3333Z"
                                fill="#2F3E5B"
                              />
                            </svg>
                          </span>
                          <input
                            type={"text"}
                            value={values.vehicleName}
                            name="vehicleName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.vehicleName && touched.vehicleName && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.vehicleName}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Vehicle Type
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          className="form-control"
                          value={values.vehicleType}
                          onChange={handleChange}
                          name="vehicleType"
                          onBlur={handleBlur}
                        >
                          <option value="">None</option>
                          {vehicleTypes.map((item, index) => (
                            <option
                              value={item.carType}
                              key={index}
                              idkey={item._id}
                            >
                              {item.carType}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.vehicleType && touched.vehicleType && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.vehicleType}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Vehicle Model
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M5 15.8333V16.2708C5 16.6181 4.88195 16.9097 4.64584 17.1458C4.40972 17.3819 4.11111 17.5 3.75 17.5C3.40278 17.5 3.1075 17.3783 2.86417 17.135C2.62084 16.8917 2.49945 16.5967 2.5 16.25V10L4.25 5C4.33334 4.75 4.4825 4.54861 4.6975 4.39583C4.9125 4.24305 5.15222 4.16666 5.41667 4.16666H14.5833C14.8472 4.16666 15.0869 4.24305 15.3025 4.39583C15.5181 4.54861 15.6672 4.75 15.75 5L17.5 10V16.2708C17.5 16.6181 17.3819 16.9097 17.1458 17.1458C16.9097 17.3819 16.6111 17.5 16.25 17.5C15.9028 17.5 15.6078 17.3783 15.365 17.135C15.1222 16.8917 15.0006 16.5967 15 16.25V15.8333H5ZM4.83334 8.33333H15.1667L14.2917 5.83333H5.70834L4.83334 8.33333ZM6.25 13.3333C6.59722 13.3333 6.8925 13.2117 7.13584 12.9683C7.37917 12.725 7.50056 12.43 7.5 12.0833C7.5 11.7361 7.37834 11.4408 7.135 11.1975C6.89167 10.9542 6.59667 10.8328 6.25 10.8333C5.90278 10.8333 5.6075 10.955 5.36417 11.1983C5.12084 11.4417 4.99945 11.7367 5 12.0833C5 12.4306 5.12167 12.7258 5.365 12.9692C5.60834 13.2125 5.90334 13.3339 6.25 13.3333ZM13.75 13.3333C14.0972 13.3333 14.3925 13.2117 14.6358 12.9683C14.8792 12.725 15.0006 12.43 15 12.0833C15 11.7361 14.8783 11.4408 14.635 11.1975C14.3917 10.9542 14.0967 10.8328 13.75 10.8333C13.4028 10.8333 13.1075 10.955 12.8642 11.1983C12.6208 11.4417 12.4994 11.7367 12.5 12.0833C12.5 12.4306 12.6217 12.7258 12.865 12.9692C13.1083 13.2125 13.4033 13.3339 13.75 13.3333Z"
                                fill="#2F3E5B"
                              />
                            </svg>
                          </span>
                          <input
                            type={"text"}
                            value={values.vehicleModel}
                            name="vehicleModel"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.vehicleModel && touched.vehicleModel && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.vehicleModel}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Vehicle Make
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M5 15.8333V16.2708C5 16.6181 4.88195 16.9097 4.64584 17.1458C4.40972 17.3819 4.11111 17.5 3.75 17.5C3.40278 17.5 3.1075 17.3783 2.86417 17.135C2.62084 16.8917 2.49945 16.5967 2.5 16.25V10L4.25 5C4.33334 4.75 4.4825 4.54861 4.6975 4.39583C4.9125 4.24305 5.15222 4.16666 5.41667 4.16666H14.5833C14.8472 4.16666 15.0869 4.24305 15.3025 4.39583C15.5181 4.54861 15.6672 4.75 15.75 5L17.5 10V16.2708C17.5 16.6181 17.3819 16.9097 17.1458 17.1458C16.9097 17.3819 16.6111 17.5 16.25 17.5C15.9028 17.5 15.6078 17.3783 15.365 17.135C15.1222 16.8917 15.0006 16.5967 15 16.25V15.8333H5ZM4.83334 8.33333H15.1667L14.2917 5.83333H5.70834L4.83334 8.33333ZM6.25 13.3333C6.59722 13.3333 6.8925 13.2117 7.13584 12.9683C7.37917 12.725 7.50056 12.43 7.5 12.0833C7.5 11.7361 7.37834 11.4408 7.135 11.1975C6.89167 10.9542 6.59667 10.8328 6.25 10.8333C5.90278 10.8333 5.6075 10.955 5.36417 11.1983C5.12084 11.4417 4.99945 11.7367 5 12.0833C5 12.4306 5.12167 12.7258 5.365 12.9692C5.60834 13.2125 5.90334 13.3339 6.25 13.3333ZM13.75 13.3333C14.0972 13.3333 14.3925 13.2117 14.6358 12.9683C14.8792 12.725 15.0006 12.43 15 12.0833C15 11.7361 14.8783 11.4408 14.635 11.1975C14.3917 10.9542 14.0967 10.8328 13.75 10.8333C13.4028 10.8333 13.1075 10.955 12.8642 11.1983C12.6208 11.4417 12.4994 11.7367 12.5 12.0833C12.5 12.4306 12.6217 12.7258 12.865 12.9692C13.1083 13.2125 13.4033 13.3339 13.75 13.3333Z"
                                fill="#2F3E5B"
                              />
                            </svg>
                          </span>
                          <input
                            type={"text"}
                            value={values.vehicleMake}
                            name="vehicleMake"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.vehicleMake && touched.vehicleMake && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.vehicleMake}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Plate Number
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M10.5 10.5H7.16667V8.83333H8.83333C9.27536 8.83333 9.69928 8.65774 10.0118 8.34518C10.3244 8.03262 10.5 7.60869 10.5 7.16667V5.5C10.5 5.05797 10.3244 4.63405 10.0118 4.32149C9.69928 4.00893 9.27536 3.83333 8.83333 3.83333H5.5V5.5H8.83333V7.16667H7.16667C6.72464 7.16667 6.30072 7.34226 5.98816 7.65482C5.67559 7.96738 5.5 8.39131 5.5 8.83333V12.1667H10.5M13.8333 13.8333H2.16667V2.16667H13.8333M13.8333 0.5H2.16667C1.72464 0.5 1.30072 0.675595 0.988155 0.988155C0.675595 1.30072 0.5 1.72464 0.5 2.16667V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5H13.8333C14.2754 15.5 14.6993 15.3244 15.0118 15.0118C15.3244 14.6993 15.5 14.2754 15.5 13.8333V2.16667C15.5 1.72464 15.3244 1.30072 15.0118 0.988155C14.6993 0.675595 14.2754 0.5 13.8333 0.5Z"
                                fill="#2F3E5B"
                              />
                            </svg>
                          </span>
                          <input
                            type={"text"}
                            value={values.plateNumber}
                            name="plateNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.plateNumber && touched.plateNumber && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.plateNumber}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Vehicle Year
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M10.5 10.5H7.16667V8.83333H8.83333C9.27536 8.83333 9.69928 8.65774 10.0118 8.34518C10.3244 8.03262 10.5 7.60869 10.5 7.16667V5.5C10.5 5.05797 10.3244 4.63405 10.0118 4.32149C9.69928 4.00893 9.27536 3.83333 8.83333 3.83333H5.5V5.5H8.83333V7.16667H7.16667C6.72464 7.16667 6.30072 7.34226 5.98816 7.65482C5.67559 7.96738 5.5 8.39131 5.5 8.83333V12.1667H10.5M13.8333 13.8333H2.16667V2.16667H13.8333M13.8333 0.5H2.16667C1.72464 0.5 1.30072 0.675595 0.988155 0.988155C0.675595 1.30072 0.5 1.72464 0.5 2.16667V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5H13.8333C14.2754 15.5 14.6993 15.3244 15.0118 15.0118C15.3244 14.6993 15.5 14.2754 15.5 13.8333V2.16667C15.5 1.72464 15.3244 1.30072 15.0118 0.988155C14.6993 0.675595 14.2754 0.5 13.8333 0.5Z"
                                fill="#2F3E5B"
                              />
                            </svg>
                          </span>
                          <input
                            type={"number"}
                            value={values.vehicleYear}
                            name="vehicleYear"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            min="1999"
                            max="2025"
                          />
                        </div>
                        {errors.vehicleYear && touched.vehicleYear && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.vehicleYear}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Vehicle Color
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M10.5 10.5H7.16667V8.83333H8.83333C9.27536 8.83333 9.69928 8.65774 10.0118 8.34518C10.3244 8.03262 10.5 7.60869 10.5 7.16667V5.5C10.5 5.05797 10.3244 4.63405 10.0118 4.32149C9.69928 4.00893 9.27536 3.83333 8.83333 3.83333H5.5V5.5H8.83333V7.16667H7.16667C6.72464 7.16667 6.30072 7.34226 5.98816 7.65482C5.67559 7.96738 5.5 8.39131 5.5 8.83333V12.1667H10.5M13.8333 13.8333H2.16667V2.16667H13.8333M13.8333 0.5H2.16667C1.72464 0.5 1.30072 0.675595 0.988155 0.988155C0.675595 1.30072 0.5 1.72464 0.5 2.16667V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5H13.8333C14.2754 15.5 14.6993 15.3244 15.0118 15.0118C15.3244 14.6993 15.5 14.2754 15.5 13.8333V2.16667C15.5 1.72464 15.3244 1.30072 15.0118 0.988155C14.6993 0.675595 14.2754 0.5 13.8333 0.5Z"
                                fill="#2F3E5B"
                              />
                            </svg>
                          </span>
                          <input
                            type={"text"}
                            value={values.vehicleColor}
                            name="vehicleColor"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.vehicleColor && touched.vehicleColor && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.vehicleColor}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Licence Number
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M10.5 10.5H7.16667V8.83333H8.83333C9.27536 8.83333 9.69928 8.65774 10.0118 8.34518C10.3244 8.03262 10.5 7.60869 10.5 7.16667V5.5C10.5 5.05797 10.3244 4.63405 10.0118 4.32149C9.69928 4.00893 9.27536 3.83333 8.83333 3.83333H5.5V5.5H8.83333V7.16667H7.16667C6.72464 7.16667 6.30072 7.34226 5.98816 7.65482C5.67559 7.96738 5.5 8.39131 5.5 8.83333V12.1667H10.5M13.8333 13.8333H2.16667V2.16667H13.8333M13.8333 0.5H2.16667C1.72464 0.5 1.30072 0.675595 0.988155 0.988155C0.675595 1.30072 0.5 1.72464 0.5 2.16667V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5H13.8333C14.2754 15.5 14.6993 15.3244 15.0118 15.0118C15.3244 14.6993 15.5 14.2754 15.5 13.8333V2.16667C15.5 1.72464 15.3244 1.30072 15.0118 0.988155C14.6993 0.675595 14.2754 0.5 13.8333 0.5Z"
                                fill="#2F3E5B"
                              />
                            </svg>
                          </span>
                          <input
                            type={"text"}
                            value={values.licenseNumber}
                            name="licenseNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.licenseNumber && touched.licenseNumber && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.licenseNumber}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col md="6" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Licence Exp Date
                        </label>
                        <div className="icon-with-text position-relative">
                          <span className="icn position-absolute">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M10.5 10.5H7.16667V8.83333H8.83333C9.27536 8.83333 9.69928 8.65774 10.0118 8.34518C10.3244 8.03262 10.5 7.60869 10.5 7.16667V5.5C10.5 5.05797 10.3244 4.63405 10.0118 4.32149C9.69928 4.00893 9.27536 3.83333 8.83333 3.83333H5.5V5.5H8.83333V7.16667H7.16667C6.72464 7.16667 6.30072 7.34226 5.98816 7.65482C5.67559 7.96738 5.5 8.39131 5.5 8.83333V12.1667H10.5M13.8333 13.8333H2.16667V2.16667H13.8333M13.8333 0.5H2.16667C1.72464 0.5 1.30072 0.675595 0.988155 0.988155C0.675595 1.30072 0.5 1.72464 0.5 2.16667V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5H13.8333C14.2754 15.5 14.6993 15.3244 15.0118 15.0118C15.3244 14.6993 15.5 14.2754 15.5 13.8333V2.16667C15.5 1.72464 15.3244 1.30072 15.0118 0.988155C14.6993 0.675595 14.2754 0.5 13.8333 0.5Z"
                                fill="#2F3E5B"
                              />
                            </svg>
                          </span>
                          <input
                            type={"date"}
                            value={values.licenseExpiryDate}
                            name="licenseExpiryDate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {errors.licenseExpiryDate &&
                          touched.licenseExpiryDate && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.licenseExpiryDate}
                            </Form.Control.Feedback>
                          )}
                      </Col>
                      <Col md="12" className="my-2">
                        <ul className="list-unstyled ps-0 mb-0 upload-fileList">
                          <li className="my-1 py-2 position-relative">
                            {" "}
                            <input
                              type="file"
                              className="position-absolute"
                              onChange={handleVehicleImage}
                              hidden
                              value={""}
                              ref={vehicleImageRef}
                              accept="image/*"
                            />
                            <div className="inner d-flex align-items-center gap-10 ">
                              <span className="icn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M4 14H14C14.2 14 14.35 13.9083 14.45 13.725C14.55 13.5417 14.5333 13.3667 14.4 13.2L11.65 9.525C11.55 9.39167 11.4167 9.325 11.25 9.325C11.0833 9.325 10.95 9.39167 10.85 9.525L8.25 13L6.4 10.525C6.3 10.3917 6.16667 10.325 6 10.325C5.83334 10.325 5.7 10.3917 5.6 10.525L3.6 13.2C3.46667 13.3667 3.45 13.5417 3.55 13.725C3.65 13.9083 3.8 14 4 14ZM2 18C1.45 18 0.979002 17.804 0.587002 17.412C0.195002 17.02 -0.000664969 16.5493 1.69779e-06 16V2C1.69779e-06 1.45 0.196002 0.979 0.588002 0.587C0.980002 0.195 1.45067 -0.000666638 2 2.88636e-08H16C16.55 2.88636e-08 17.021 0.196 17.413 0.588C17.805 0.98 18.0007 1.45067 18 2V16C18 16.55 17.804 17.021 17.412 17.413C17.02 17.805 16.5493 18.0007 16 18H2Z"
                                    fill="#2F3E5B"
                                  />
                                </svg>
                              </span>
                              <p
                                className="theme-clr fw-sbold m-0"
                                onClick={
                                  !values.vehicleImage
                                    ? handleVehicleImage
                                    : () => setFieldValue("vehicleImage", null)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Upload Vehicle Image
                                {values.vehicleImage && (
                                  <span className="text-danger">- Remove</span>
                                )}
                              </p>
                            </div>
                            {values.vehicleImage && (
                              <div className="mt-2">
                                <img
                                  src={vehicleImage}
                                  alt=""
                                  className="img-fluid w-25 h-100 border "
                                />
                              </div>
                            )}
                            {errors.vehicleImage && touched.vehicleImage && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {errors.vehicleImage}
                              </Form.Control.Feedback>
                            )}
                          </li>
                          <li className="my-1 py-2 position-relative">
                            {" "}
                            <input
                              type="file"
                              className="position-absolute"
                              onChange={handleLicenseImage}
                              hidden
                              value={""}
                              ref={licenseImageRef}
                              accept="image/*"
                            />
                            <div className="inner d-flex align-items-center gap-10 ">
                              <span className="icn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M4 14H14C14.2 14 14.35 13.9083 14.45 13.725C14.55 13.5417 14.5333 13.3667 14.4 13.2L11.65 9.525C11.55 9.39167 11.4167 9.325 11.25 9.325C11.0833 9.325 10.95 9.39167 10.85 9.525L8.25 13L6.4 10.525C6.3 10.3917 6.16667 10.325 6 10.325C5.83334 10.325 5.7 10.3917 5.6 10.525L3.6 13.2C3.46667 13.3667 3.45 13.5417 3.55 13.725C3.65 13.9083 3.8 14 4 14ZM2 18C1.45 18 0.979002 17.804 0.587002 17.412C0.195002 17.02 -0.000664969 16.5493 1.69779e-06 16V2C1.69779e-06 1.45 0.196002 0.979 0.588002 0.587C0.980002 0.195 1.45067 -0.000666638 2 2.88636e-08H16C16.55 2.88636e-08 17.021 0.196 17.413 0.588C17.805 0.98 18.0007 1.45067 18 2V16C18 16.55 17.804 17.021 17.412 17.413C17.02 17.805 16.5493 18.0007 16 18H2Z"
                                    fill="#2F3E5B"
                                  />
                                </svg>
                              </span>
                              <p
                                className="theme-clr fw-sbold m-0"
                                onClick={
                                  !values.licenseImage
                                    ? handleLicenseImage
                                    : () => setFieldValue("licenseImage", null)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Driver License Image
                                {values.licenseImage && (
                                  <span className="text-danger">- Remove</span>
                                )}
                              </p>
                            </div>
                            {values.licenseImage && (
                              <div className="mt-2">
                                <img
                                  src={licenseImage}
                                  alt=""
                                  className="img-fluid w-25 h-100 border "
                                />
                              </div>
                            )}
                            {errors.licenseImage && touched.licenseImage && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {errors.licenseImage}
                              </Form.Control.Feedback>
                            )}
                          </li>
                          <li className="my-1 py-2 position-relative">
                            {" "}
                            <input
                              type="file"
                              className="position-absolute"
                              onChange={handleInsuranceImage}
                              hidden
                              value={""}
                              ref={insuranceImageRef}
                              accept="image/*"
                            />
                            <div className="inner d-flex align-items-center gap-10 ">
                              <span className="icn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M4 14H14C14.2 14 14.35 13.9083 14.45 13.725C14.55 13.5417 14.5333 13.3667 14.4 13.2L11.65 9.525C11.55 9.39167 11.4167 9.325 11.25 9.325C11.0833 9.325 10.95 9.39167 10.85 9.525L8.25 13L6.4 10.525C6.3 10.3917 6.16667 10.325 6 10.325C5.83334 10.325 5.7 10.3917 5.6 10.525L3.6 13.2C3.46667 13.3667 3.45 13.5417 3.55 13.725C3.65 13.9083 3.8 14 4 14ZM2 18C1.45 18 0.979002 17.804 0.587002 17.412C0.195002 17.02 -0.000664969 16.5493 1.69779e-06 16V2C1.69779e-06 1.45 0.196002 0.979 0.588002 0.587C0.980002 0.195 1.45067 -0.000666638 2 2.88636e-08H16C16.55 2.88636e-08 17.021 0.196 17.413 0.588C17.805 0.98 18.0007 1.45067 18 2V16C18 16.55 17.804 17.021 17.412 17.413C17.02 17.805 16.5493 18.0007 16 18H2Z"
                                    fill="#2F3E5B"
                                  />
                                </svg>
                              </span>
                              <p
                                className="theme-clr fw-sbold m-0"
                                onClick={
                                  !values.insuranceImage
                                    ? handleInsuranceImage
                                    : () =>
                                        setFieldValue("insuranceImage", null)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Business Insurance Image
                                {values.insuranceImage && (
                                  <span className="text-danger">- Remove</span>
                                )}
                              </p>
                            </div>
                            {values.insuranceImage && (
                              <div className="mt-2">
                                <img
                                  src={insuranceImage}
                                  alt=""
                                  className="img-fluid w-25 h-100 border "
                                />
                              </div>
                            )}{" "}
                            {errors.insuranceImage &&
                              touched.insuranceImage && (
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="d-block"
                                >
                                  {errors.insuranceImage}
                                </Form.Control.Feedback>
                              )}
                          </li>
                        </ul>
                      </Col>
                      <Col lg="6" className="my-2 mt-4 btn-wrp">
                        <Button
                          onClick={handleSubmit}
                          className="common-btn d-flex align-items-center justify-content-center border-0 w-100"
                        >
                          Submit
                        </Button>
                      </Col>
                      <Col lg="6" className="my-2 mt-4 btn-wrp">
                        <Button
                          onClick={() => setStep("0")}
                          className="common-btn d-flex align-items-center justify-content-center border-0 w-100"
                        >
                          Back
                        </Button>
                      </Col>

                      <Col xs="12">
                        <div className="mt-3">
                          <p className="m-0">
                            Already have an account?{" "}
                            <Link className="form-link theme-clr fw-sbold">
                              Download App
                            </Link>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Form>
              </Modal.Body>
            </Modal>
          </Fragment>
        );
      }}
    </Formik>
  );
};
export default SignUpForm;
