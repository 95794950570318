// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/messaging";

class Firebase {
  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyC8D5ncJ7QAmSNv_yQoAaJXB9YzVSaPMs0",
      authDomain: "test-76350.firebaseapp.com",
      projectId: "test-76350",
      storageBucket: "test-76350.appspot.com",
      messagingSenderId: "816054216633",
      appId: "1:816054216633:web:b79464923d27331c523868",
      measurementId: "G-KPXRGH71NN",
    };

    firebase.initializeApp(firebaseConfig);
  }

  getToken = async () => {
    const messaging = firebase?.messaging();

    return await messaging?.getToken();
  };

  onMessageListener = () => {
    return new Promise((resolve) => {
      const messaging = firebase?.messaging();
      messaging?.onMessage((payload) => {
        resolve(payload);
      });
    });
  };
}

let _firebaseInstance = null;

export const getFirebase = () => {
  if (!_firebaseInstance) {
    _firebaseInstance = new Firebase();

    return _firebaseInstance;
  }

  return _firebaseInstance;
};
