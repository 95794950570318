import {
  put,
  call,
  take,
  every,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import * as CONST from "./constant";
import * as ACTION from "./action";
import * as API from "../../services/ApiCalls";
import { toast } from "react-toastify";

function* vehicleTypeSaga({ payload, callBack }) {
  try {
    const response = yield call(API.VEHICLE_TYPE, payload);
    if (response.data.status == "success") {
      // toast.success(response.data.message);
      callBack && callBack(response.data);
    }

    yield put(ACTION.vehicleTypeSuccess(response.data));
  } catch (error) {
    yield put(ACTION.vehicleTypeFail(error));
  }
}


function* VehicleTypeSaga() {
  yield takeEvery(CONST.VEHICLE_TYPE, vehicleTypeSaga);
}

export default VehicleTypeSaga;
