import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SignUpForm from "./SignUpForm";

export const BannerSec = () => {
  const [showSignUp, setShowSignUp] = useState("");
  const handleSignUp = () => {
    setShowSignUp(!showSignUp);
  };
  return (
    <>
      <section
        className="signup-sec py-5"
        style={{ backgroundImage: 'url("../images/loginbg.png")' }}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="my-2">
              <div className="bannerContent text-start pe-lg-5">
                <h2 className="text-dark fw-bold">
                  Deliver with <span className="theme-clr">Tucson Eats</span>
                </h2>
                {/* <p className="m-0 py-2">
                  <span className="fw-sbold d-block">
                    No boss. Flexible schedule. Quick pay.
                  </span>
                  Now you can make money by delivering food orders that people
                  crave using the imsohungry app—all while exploring your city.
                </p> */}
                <p className="m-0 py-2">
                  <span className="fw-sbold d-block">
                    No boss. Flexible schedule. Quick pay.
                  </span>
                  Now you can make money by delivering food orders that people
                  crave using the tucsoneats app—all while exploring your city.
                </p>

                <div className="btn-wrp mt-4 d-flex align-items-center flex-wrap gap-10">
                  <Button
                    to=""
                    className="btn common-btn rounded-pill d-flex align-items-center justify-content-center border-0"
                    onClick={handleSignUp}
                  >
                    Sign up to deliver  
                  </Button>
                  <p className="m-0">
                    Get our Driver App{" "}
                    <a
                      href="https://apps.apple.com/us/app/deliver-usa/id6444758314"
                      className="theme-clr"
                    >
                      Download
                    </a>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="6" className="my-2 text-center img-wrpper">
              <img src="../images/food boy.png" alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>
      <SignUpForm showSignUp={showSignUp} setShowSignUp={setShowSignUp} />
    </>
  );
};
export default BannerSec;
