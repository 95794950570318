import * as CONST from "./constant";

const intialState = {
  data: [],
  id: null,
  freshMarketRestaurant: [],
  error: null,
  latLng: {},
  tabData: [],
  NearbyRestaurantLoader: false,
  categories: [],
};

const NearbyRestaurantReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case CONST.NEARBY_RESTAURANT_CATEGORIES:
      return {
        ...state,
      };

    case CONST.NEARBY_RESTAURANT_CATEGORIES_SUCCESS:
      console.log(payload , "payload categories")
      return {
        ...state,
        categories: payload.categories,
      };

    case CONST.NEARBY_RESTAURANT:
      return {
        ...state,
        NearbyRestaurantLoader: true,
      };
    case CONST.NEARBY_RESTAURANT_SUCCESS:
      return {
        ...state,
        id: payload._id,
        data: payload,
        error: null,
        NearbyRestaurantLoader: false,
      };
    case CONST.NEARBY_FRESH_RESTAURANT_SUCCESS:
      console.log(payload, "payload success fresh");
      return {
        ...state,
        freshMarketRestaurant: payload?.freshMarketItems,
        error: null,
      };

    case CONST.NEARBY_RESTAURANT_FAIL:
      return {
        ...state,
        error: payload,
        NearbyRestaurantLoader: false,
      };
    /////======================COllection Tab==============///////

    case CONST.COLLECTION_TAB_SUCCESS:
      return {
        ...state,
        id: payload._id,
        tabData: payload,
        error: null,
      };
    case CONST.COLLECTION_TAB_FAIL:
      return {
        ...state,
        error: payload,
      };

    // ============== restaurant detail ========= //

    case CONST.RESTAURANT_DETAIL_SUCCESS:
      return {
        ...state,
        id: payload._id,
        data: { ...payload },
        error: null,
      };
    case CONST.RESTAURANT_DETAIL_FAIL:
      return {
        ...state,
        error: payload,
      };

    case CONST.FRESH_MARKET_DETAIL_SUCCESS:
      return {
        ...state,
        freshMarketData: payload,
        error: null,
      };

    case CONST.FRESH_MARKET_DETAIL_FAIL:
      return {
        ...state,
        error: payload,
      };

    // ============== lat lng ========= //

    case CONST.LAT_LNG:
      console.log(payload, "rdcer ");
      return {
        ...state,
        latLng: { ...payload },
        error: null,
      };

    default:
      return state;
      break;
  }
};

export default NearbyRestaurantReducer;
