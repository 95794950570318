export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAIL = "ADD_ADDRESS_FAIL";

export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAIL = "GET_ADDRESS_FAIL";

export const VIEW_ADDRESS = "VIEW_ADDRESS";
export const VIEW_ADDRESS_SUCCESS = "VIEW_ADDRESS_SUCCESS";
export const VIEW_ADDRESS_FAIL = "VIEW_ADDRESS_FAIL";

export const EDIT_ADDRESS = "EDIT_ADDRESS";
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAIL = "EDIT_ADDRESS_FAIL";

export const DEL_ADDRESS = "DEL_ADDRESS";
export const DEL_ADDRESS_SUCCESS = "DEL_ADDRESS_SUCCESS";
export const DEL_ADDRESS_FAIL = "DEL_ADDRESS_FAIL";

export const ADD_PAYMENT = "ADD_PAYMENT";
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
export const ADD_PAYMENT_FAIL = "ADD_PAYMENT_FAIL";

export const PAYMENT_LIST = "PAYMENT_LIST";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAIL = "PAYMENT_LIST_FAIL";

export const DELETE_CARD = "DELETE_CARD";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAIL = "DELETE_CARD_FAIL";

export const ADD_MONEY = "ADD_MONEY";
export const ADD_MONEY_SUCCESS = "ADD_MONEY_SUCCESS";
export const ADD_MONEY_FAIL = "ADD_MONEY_FAIL";

export const ADD_CART = "ADD_CART";
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAIL = "ADD_CART_FAIL";

export const UPDATE_ADD_CART = "UPDATE_ADD_CART";
export const UPDATE_ADD_CART_SUCCESS = "UPDATE_ADD_CART_SUCCESS";
export const UPDATE_ADD_CART_FAIL = "UPDATE_ADD_CART_FAIL";

export const ADD_CART_QUANTITY = "ADD_CART_QUANTITY";
export const ADD_CART_QUANTITY_SUCCESS = "ADD_CART_QUANTITY_SUCCESS";
export const ADD_CART_QUANTITY_FAIL = "ADD_CART_QUANTITY_FAIL";

export const USER_CART = "USER_CART";
export const USER_CART_SUCCESS = "USER_CART_SUCCESS";
export const USER_CART_FAIL = "USER_CART_FAIL";

export const USER_CART_REMOVE = "USER_CART_REMOVE";
export const USER_CART_REMOVE_SUCCESS = "USER_CART_REMOVE_SUCCESS";
export const USER_CART_REMOVE_FAIL = "USER_CART_REMOVE_FAIL";

export const SAVE_DATA = "SAVE_DATA";

export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";

export const PROMOLIST = "PROMOLIST";
export const PROMOLIST_SUCCESS = "PROMOLIST_SUCCESS";
export const PROMOLIST_FAIL = "PROMOLIST_FAIL";

export const DRIVERLIST = "DRIVERLIST";
export const DRIVERLIST_SUCCESS = "DRIVERLIST_SUCCESS";
export const DRIVERLIST_FAIL = "DRIVERLIST_FAIL";

export const UBER_DRIVERLIST = "UBER_DRIVERLIST";
export const UBER_DRIVERLIST_SUCCESS = "UBER_DRIVERLIST_SUCCESS";
export const UBER_DRIVERLIST_FAIL = "UBER_DRIVERLIST_FAIL";

export const COMPARE_LIST = "COMPARE_LIST";
export const COMPARE_LIST_SUCCESS = "COMPARE_LIST_SUCCESS";
export const COMPARE_LIST_FAIL = "COMPARE_LIST_FAIL";

export const FAQ_LIST = "FAQ_LIST";
export const FAQ_LIST_SUCCESS = "FAQ_LIST_SUCCESS";
export const FAQ_LIST_FAIL = "FAQ_LIST_FAIL";

export const ORDERS_LIST = "ORDERS_LIST";
export const ORDERS_LIST_SUCCESS = "ORDERS_LIST_SUCCESS";
export const ORDERS_LIST_FAIL = "ORDERS_LIST_FAIL";

export const ORDER_DETAIL = "ORDER_DETAIL";
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS";
export const ORDER_DETAIL_FAIL = "ORDER_DETAIL_FAIL";

export const FEEDBACK = "FEEDBACK";
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
export const FEEDBACK_FAIL = "FEEDBACK_FAIL";

export const CONTACTUS = "CONTACTUS";
export const CONTACTUS_SUCCESS = "CONTACTUS_SUCCESS";
export const CONTACTUS_FAIL = "CONTACTUS_FAIL";

export const GUEST_REGISTER = "GUEST_REGISTER";
export const GUEST_REGISTER_SUCCESS = "GUEST_REGISTER_SUCCESS";
export const GUEST_REGISTER_FAIL = "GUEST_REGISTER_FAIL";
