import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const DriverNavigation = () => {
  return (
    <>
      <section className="siup-nav bg-white shadow-sm sticky-top py-2">
        <Container>
          <Row>
            <Col lg="12">
              <div className="d-flex align-items-center justify-content-between gap-10 flex-wrap">
                <div className="left">
                  <h4 className="fw-bold">Driver</h4>
                </div>
                {/* <div className="right">
                                <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10">
                                    <li className="">
                                        <Link to="" className='text-muted'>
                                            Overview
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="" className='text-muted'>
                                         Earning
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="" className='text-muted'>
                                            Safety
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default DriverNavigation;
